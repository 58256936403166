import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: localStorage.getItem("token"),
    userInfo: ""
  },
  mutations: {//方法类似java中set
    SET_USER: (state, user) => {
      state.user = user
      localStorage.setItem("token",user)//在浏览器中缓存
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
      sessionStorage.setItem("userInfo",JSON.stringify(userInfo))
    },
    REMOVE_INFO: (state) => {
      state.token = ''
      state.userInfo = {}
      localStorage.setItem("token",'')
      sessionStorage.setItem("userInfo",JSON.stringify(''))
    }
  },
  getters: {//get
    getUser: state => {
      return state.userInfo
    },
  },
  actions: {
  },
  modules: {
  }
})
