<template>
  <el-container style="height: 100vh" id="el-container">
    <el-header style="height: 65px">
      <i class="el-icon-s-platform"></i> 智 能 问 答 后 台 管 理
      <button style="float: right;margin: 12px" class="bttn-unite bttn-xs bttn-primary" @click="clearToken">登出</button>
      <button style="float: right;margin: 12px" class="bttn-unite bttn-xs bttn-primary" @click="goToPage('/editPhone')"><i class="el-icon-phone"/></button>
    </el-header>
    <el-row class="tac" style="height: auto">
      <el-col :span="3" style="height: 100%">
        <el-menu :collapse="isCollapse"
                 style="height: 100%"
            default-active="1-1"
            class="el-menu-vertical-demo"
          >

          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-s-comment"></i>
              <span>留言管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="1-1" @click="goToPage('/newMessage')">
                <span class="menuitem" >最新留言</span>
              </el-menu-item>
              <el-menu-item index="1-2" @click="goToPage('/oldMessage')">
                <span class="menuitem">历史记录</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-s-opportunity"></i>
              <span slot="title">问题管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="2-1" @click="goToPage('/questionTable')">
                <span class="menuitem">查询问题</span>
              </el-menu-item>
              <el-menu-item index="2-2" @click="goToPage('/questionAdd')">
                <span class="menuitem" >添加问题</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-s-tools"></i>
              <span slot="title">账号管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="3-1" @click="goToPage('/editPhone')">
                <span class="menuitem" >修改联系电话</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-col>
      <el-col :span="21" >
        <router-view/>
      </el-col>
    </el-row>


  </el-container>
</template>
<script>


export default {
  name: "Artificial",
  data() {
    return {
      isCollapse: false
    }
  },
  created() {

  },
  methods:{

    clearToken(){
      this.$store.commit("REMOVE_INFO")
      this.$router.push("/Login")
    },
    goToPage(path){
      console.log(path)
      this.$router.push(path)
    },

  }
}
</script>

<style scoped>
.menuitem{
  font-size: 12px;
  color: #446087;
}
.radioCollapse{
  position: absolute;
  margin: 20px;
}
.radioCollapse-icon{
  position: relative;
  z-index: 99;
}
.el-header{
  background-color: #446087;
  color: #ddeaff;
  text-align:left;
  line-height: 60px;
  width: 100%;
  font-size: 20px;

}
.el-main {
  background-color: #E9EEF3;
  color: #333;
  /*text-align: center;*/
  height: 100vh;
  padding: 0;
}
.item {
  margin-top: 10px;
}



.tac/deep/.el-menu-item{
  min-width: 100px;
}




</style>