<template>
  <el-card class="creatorMessage" shadow="hover">
    <el-row >
      <el-col :span="4">
        <img  class="img" :src="value.url"  alt=""/>
      </el-col>
      <el-col :span="20">
        <div class="contain">
            <div class="contain-man">
                  <i class="el-icon-user-solid"/> {{value.name}}
                  <i class="el-icon-s-help"/> {{value.work}}
            </div>
            <div class="contain-qq">
                <i class="el-icon-s-opportunity"/>  QQ/邮箱: {{value.qq}}
            </div>
        </div>
      </el-col>
    </el-row>


  </el-card>
</template>

<script>
export default {
  name: "CreatorMessage",
  props:{
    value:''
  }
}
</script>

<style scoped>
.creatorMessage{
  width: 100%;
  margin-bottom: 10px;
}
.creatorMessage/deep/.el-card__body{
  padding: 10px;
}
.img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
}
.contain{
  padding-left: 10px;
  font-size: 14px;
  color: gray;
  font-family: 楷体,serif;
  font-weight: bold;
}
.contain-man{
  margin-bottom: 10px;
}
.contain-qq{

}
</style>