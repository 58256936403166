import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import "element-ui/lib/theme-chalk/index.css"
import axios from "axios";
import "./utils/axios"
import '@/assets/css/global.css'
import '@/assets/css/emoji.css'
import '@/assets/css/bttn.min.css'
import '@/assets/css/cartoon.css'

Vue.prototype.$axios = axios
Vue.use(Element)

Vue.prototype.host = process.env.BASE_URL
//生产环境时自动设置为 false 以阻止 vue 在启动时生成生产提示。
Vue.config.productionTip = (process.env.NODE_ENV !== 'production')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.getDate = function (dateTimeStamp) {

  const minute = 1000 * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const halfamonth = day * 15;
  const month = day * 30;
  const year = month * 12;
  if (dateTimeStamp === undefined) {

    return false;
  } else {
    dateTimeStamp = dateTimeStamp.replace(/\-/g, "/");

    const sTime = new Date(dateTimeStamp).getTime();//把时间pretime的值转为时间戳

    const now = new Date().getTime();//获取当前时间的时间戳

    const diffValue = now - sTime;

    if (diffValue < 0) {
     // console.log("结束日期不能小于开始日期！");
      return dateTimeStamp
    }

    const monthC = diffValue / month;
    const weekC = diffValue / (7 * day);
    const dayC = diffValue / day;
    const hourC = diffValue / hour;
    const minC = diffValue / minute;
    const yearC = diffValue / year;
    if (yearC >= 1) {
     // console.log(yearC + "年前");
      return yearC + "年前"
    } else if (monthC >= 1) {
     // console.log(parseInt(monthC) + "个月前");
      return parseInt(monthC) + "个月前"
    }
    else if (weekC >= 1) {
    //  console.log(parseInt(weekC) + "周前")
      return parseInt(weekC) + "周前"
    }
    else if (dayC >= 1) {
    //  console.log(parseInt(dayC) + "天前")
      return parseInt(dayC) + "天前"
    }
    else if (hourC >= 1) {
    //  console.log(parseInt(hourC) + "个小时前")
      return parseInt(hourC) + "个小时前"
    }
    else if (minC >= 1) {
     // console.log(parseInt(minC) + "分钟前")
      return parseInt(minC) + "分钟前"
    } else {
    //  console.log("刚刚")
      return "刚刚"
    }
  }

}

