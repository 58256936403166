<template>
                <div class="add-main">
                  <div class="add-question" >
                    <el-input type="textarea"  resize="none" placeholder="请输入问题..." v-model="question" />
                  </div>
                  <div class="add-answer">
                    <MyEditor @editOk="addNewQuestion"/>
                  </div>
                </div>
</template>

<script>
import MyEditor from "@/components/MyEditor";
export default {
  name: "QuestionAdd",
  components:{MyEditor},
  data() {
    return {
      question:"",
    }
  },
  methods:{
    addNewQuestion(html){
      if(this.question===""||this.question.match(/^\s+$/)) {
        this.$message({
          showClose: true,
          message: '问题不能为空！',
          type: 'warning'
        })
        return
      }
      else
        this.$axios.post("/question/addQuestion", {question:this.question,answer:html} ).then(res=>{
          if(res.code==="200")
            this.$message({
              showClose: true,
              message: '提交成功！',
              type: 'success'
            });
          this.$router.push("/questionTable")
        }).catch(err=>{
          console.log("添加问题出错")
        })
    }
  },
}
</script>

<style scoped>
.add-main{
  width: 70%;
  margin: auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.add-question{
  padding: 10px;
}
.add-question/deep/.el-textarea__inner{
  min-height: 50px;
  max-height: 200px;
  line-height: 25px;
  font-size: 18px;
  font-family: "微软雅黑 Light",serif;
  font-weight: bold;
}
.add-answer{
  padding: 10px;

}

</style>