<template>
            <div style="overflow: auto;height: 100vh;text-align: center;padding: 50px">
              <el-empty description="暂无记录..." v-if="dealMessage.length===0" :image="require('../../assets/picture/empty.png')"/>
              <div v-else>
                <Message class="fadeInDown" :key="item.messageId" :message="item"   v-for="item in dealMessage" />
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :current-page="currentPage2"
                    :page-size="pageSize2"
                    :total="total2"
                    style="margin-bottom: 50px;"
                    @current-change="getDealMessage"
                >
                </el-pagination>
              </div>

            </div>
</template>

<script>
import Message from "@/components/Message";

export default {
  name: "OldMessage",
  components:{Message},
  data() {
    return {
      currentPage2:1,
      total2:0,
      pageSize2:1,
      dealMessage:[],
    }
  },
  created() {
    this.getDealMessage(1)
  },
  methods:{
    getDealMessage(currentPage){
      this.$axios.get("/customer/getChatList/1/"+currentPage ).then(res=>{
        this.dealMessage=res.data.list;
        this.currentPage2 = res.data.pageNum
        this.total2 = res.data.total
        this.pageSize2 = res.data.pageSize
      }).catch(err=>{
        console.log("Artificial/getDealMessage出错")
      })
    },
  },
}
</script>

<style scoped>

</style>