<template>
  <div class="main">
    <el-card class="card" shadow="hover">
          <div slot="header" class="clearfix">
                 <span class="card-title">修改联系电话，便于咨询联系</span>
          </div>
          <div class="card-contain">
            <el-form label-position="right" :rules="rules" ref="form" label-width="60px" :model="form">
              <el-form-item label="电话" prop="phone" >
                <el-input  placeholder="请输入电话号码..." v-model="form.phone"/>
              </el-form-item>
              <el-form-item style="text-align: right">
                <el-button  type="primary" size="small" round @click="cancel" >取消</el-button>
                <el-button  type="primary" size="small" round @click="ok" >修改</el-button>
              </el-form-item>
            </el-form>
          </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "EditPhone",
  data() {
    return {
      form: {phone: ''},
      rules: {
        phone: [
          {required: true, message: '请输入联系方式', trigger: 'blur'},
        ],
      }
    }
  },
  created() {
    this.init()
  },
  methods:{
    init(){
      this.$axios.get("/user/getInfo").then(res=>{
        this.form.phone=res.data.phone
      }).catch(err=>{
        this.$message.error("服务端出错")
      })
    },
    ok(){
      if(this.form.phone===''||this.form.phone.match(/^\s+$/))
        return
      this.$axios.post("/user/updateUser",{id:1,phone:this.form.phone}).then(res=>{
        if(res.code==='200')
          this.$message.success('修改成功')
        else
          this.$message.error('修改失败')
      }).catch(err=>{
          this.$message.error("服务端出错")
      })
    },
    cancel(){
      this.$refs['form'].resetFields();
    }
  }
}
</script>

<style scoped>
.main{
  /*text-align: center;*/
}
.card{
  margin: 10%  auto;
  width: 40%;
  height: 250px;

}
.card-title{
  font-size: 14px;
  color: gray;
  font-weight: bold;
}
.card-contain{

}
</style>