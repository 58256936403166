<template>

  <div class="card ">

    <div class="header" @mouseenter="$emit('moveAble',true)" @mouseleave="$emit('moveAble',false)">
      <span style="padding-left: 20px;">
          <img style="width: 60px;height: 60px;vertical-align: middle;" src="../assets/picture/机器人2.png" alt=""/>
      </span>
      <span style="padding-left: 20px;font-weight: bold;color:white;font-size: 22px;line-height: 60px" class="bounce-in-left">智能问答助手</span>
    </div>

    <div id="mainFoot">
      <el-row >
        <el-col :span="16">
          <div  class="">
            <div class="main"  id="chatPan">
              <chat-bubble  :item="item" :itemId="item.id" v-for="(item,index) in chatList" :key="item.id" @addChat="addChat" @goToQuestion="goToQuestion" />
            </div>
            <div class="footer">
              <div>
                <el-popover placement="top-start" width="400" trigger="hover">
                  <div style="overflow: auto;height:200px">
                    <img :title="item.tip" class="oneEmoji fade-in" :src="require('../assets/emoij/'+item.title)"  @click="selectEmoji(item)" v-for="(item,index) in emojiList" :key="index" alt=""/>
                  </div>
                  <img src="../assets/picture/smail.png" class="bounce-in-left" id="emoji" @click="emoij" alt="" slot="reference" style="padding:0 25px"/>
                </el-popover>
                <el-tooltip class="item bounce-in-left" effect="light" content="留言" placement="top" >
                  <img src="../assets/picture/message.png" id="message" @click="show('mainFoot','leaveMes')" alt="" style="padding: 0"/>
                </el-tooltip>
                <el-tooltip class="item bounce-in-left" effect="light" content="转人工" placement="top">
                  <img src="../assets/picture/service.png" id="service" @click="artificial" alt="" style="float: right;padding: 0 25px"/>
                </el-tooltip>
                <el-tooltip class="item bounce-in-left" effect="light" content="发送" placement="top">
                  <img src="../assets/picture/send.png" id="sendButton" @click="sendQuestion" alt="" @keyup.shift.enter="sendQuestion()" style="float: right;"/>
                </el-tooltip>

              </div>
              <div>
                <el-input borderVisible="false" type="textarea" @keydown.enter.shift.native="sendQuestion"
                          class="edit"  resize="none" id="edit" placeholder="请输入问题(shift+Enter发送)" v-model="textarea"/>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="side">
            <el-tabs type="border-card" style="height: 100%;">
              <el-tab-pane label="常见问题" style="overflow-y: auto;height: 100%" >
                     <div class="pan1-refresh">
                       <i class="el-icon-refresh" ></i>
                       <span @click="reFresh">换一批</span>
                     </div>
                     <el-empty description="请求超时..." v-if="sideQuestionList.length===0" :image="require('../assets/picture/empty.png')"/>
                     <div class="pane1-div" v-for="(item,index) in sideQuestionList">
                       <span class="pane1-number">{{index+1}}</span>
                       <span class="pane1-contain" @click="goToQuestion(item)">
                            {{item}}
                       </span>
                     </div>
              </el-tab-pane>
              <el-tab-pane label="新增问题" style="overflow-y: auto;height: 100%" >
                <div slot="label" @click="getNewQuestion">新增问题</div>
                   <el-empty description="暂无数据" v-if="newestQuestionList.length===0" :image="require('../assets/picture/empty.png')"/>
                  <div class="pane1-div" v-for="(item,index) in newestQuestionList">
                  <span class="pane1-number">{{index+1}}</span>
                  <span class="pane1-contain" @click="goToQuestion(item.question)">
                            {{item.question}}
                       </span>
                </div>
              </el-tab-pane>
              <el-tab-pane label="关于我们" style="overflow-y: auto;height: 100%">
                <div class="statement">
                  此系统由湖北工业大学大数据BD&KE实验室提供，由陈建峡老师提供指导
                </div>
                <CreatorMessage v-for="(item,index) in creator" :value="item" />
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
      </el-row>

    </div>


    <div style="display: none;width: 100%;height:540px;text-align: center "  id="leaveMes">
      <el-card  style="height: 100%;">
        <el-form label-position="right" :rules="rules" ref="messageLeave" label-width="60px" :model="messageLeave">
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="messageLeave.email" ></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input v-model="messageLeave.phone" ></el-input>
          </el-form-item>
          <el-form-item label="内容" prop="content" >
            <el-input required style="font-size: 18px;font-weight: bold"    type="textarea" resize="none"  v-model="messageLeave.content"></el-input>
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button   class="bttn-unite bttn-md bttn-primary" type="primary"  @click="show('leaveMes','mainFoot'),resetForm('messageLeave')">取消</el-button>
            <el-button  class="bttn-unite bttn-md bttn-primary" type="primary" @click="postMes('messageLeave')" >提交</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <div style="display: none;width: 100%;height:540px;text-align: center "  id="result">
      <el-result  icon="success"  title="提交成功" style="height: 100%;" :subTitle="subTip+' s 后自动返回'">
        <template slot="extra">
          <el-button   class="bttn-unite bttn-md bttn-primary" type="primary"  @click="show('result','mainFoot'),resetForm('messageLeave')">返回</el-button>
        </template>
      </el-result>
    </div>
  </div>


</template>

<script>

import chatBubble from "@/components/chatBubble";
import Test from "@/components/MyEditor";
import {nanoid} from "nanoid";
import CreatorMessage from "@/components/CreatorMessage";
export default {
  name: "Dialog",
  components:{CreatorMessage, Test, chatBubble},

  data() {
    return {
      sideQuestionList:[],
      newestQuestionList:[],
      emojiList:[],
      rules: {
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {  message: '邮箱格式错误', trigger: 'blur',pattern:/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/}
        ],
        phone:[
          { required: true, message: '请输入电话', trigger: 'blur' },
          { min:11,max:11, message: '请输入11位正确电话号码', trigger: 'blur',pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/ },
        ],
        content: [
          { required: true, message: '请输入内容', trigger: 'change' },
        ],},
      messageLeave:{email:"",content:"",phone:""},
      textarea: '',
      subTip:3,
      chatList:[{id:nanoid(),isUser:false,isList:false,contain:"您好，请问有什么可以帮您~"},],
      creator:[{name:'刘迪派',url:require('../assets/picture/creator1.jpg'),work:'后端开发',qq:'1695556159'},
        {name:'张云飞',url:require('../assets/picture/creator2.jpg'),work:'前端开发',qq:'2069708864'},
        {name:'陈浩然',url:require('../assets/picture/creator3.jpg'),work:'算法设计',qq:'1810863407'}]
    }
  },

  created() {
    //参数：1.路径；2.是否遍历子目录；3.正则表达式
    //加载表情包
    const files = require.context("../assets/emoij", true, /\.png$/).keys();
    files.forEach(item=>{
      this.emojiList.push({title:item.slice(2),tip:item.substring(2,item.lastIndexOf("."))})
    })
    this.reFresh()
    this.getNewQuestion()
  },
  watch:{
    'chatList': 'scrollToBottom',
  },
  methods:{

    //重置聊天记录
    reSet(){
      this.chatList=[];
      this.chatList.push({isUser:false,tip:"empty",type:0,contain:"您好，请问有什么可以帮您~"})
    },
    //得到最新问题
    getNewQuestion(){
      this.$axios.post("/answer/getNewQuestion").then(res=>{
        this.newestQuestionList=[];
        this.newestQuestionList=res.data;
      }).catch(err=>{
       console.log("RecomendQuestion/reFresh出错")
      })
    },
    //得到侧边栏展示的推荐问题列表
    reFresh(){
      this.$axios.get("/recommend/getList",{params: {content:""}}).then(res=>{
        this.sideQuestionList=[];
        this.sideQuestionList=res.data.recommendList;
      }).catch(err=>{
        console.log("RecomendQuestion/reFresh出错")
      })
    },
    //将滚动条拉到底
    scrollToBottom: function () {
      this.$nextTick(() => {
        var div = document.getElementById('chatPan')
        div.scrollTop = div.scrollHeight;
      })

    },
    //选择表情
    selectEmoji(item){
      this.textarea=this.textarea+"【"+item.tip+"】";
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //提交留言信息
    postMes(val){
      this.$refs[val].validate((valid) => {
        if (valid) {
          this.$axios.post("/customer/addMessage", this.messageLeave ).then(res=>{
            this.show("leaveMes","result");
            this.messageLeave={};
          }).catch(err=>{
            console.log("Dialog/postMes出错")
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //添加聊天信息
    addChat(val){
      this.chatList.push(val);
      },
    //发送问题
    sendQuestion(){
      if(this.textarea==="")
        return;
      this.chatList.push({id:nanoid(),isUser:true,isList:false,contain:this.textarea});
      this.$axios.get("/recommend/getCommendList",{params: {content:this.textarea}}).then(res=>{
        if(res.data.recommendList.length>0)
          this.addChat({id:nanoid(),isUser:false,isList:true,contain:res.data.recommendList})
         else
          this.addChat({id:nanoid(),isUser:false,isList:false,contain:"诶~人家没看懂耶，要不您换个说法呢~"})
      }).catch(err=>{
        console.log("Dialog/sendQuestion出错")
      })
      this.textarea="";
    },
    //不可删
    emoij(){

    },
    show(id1,id2){
        document.getElementById(id1).style.display="none";
        document.getElementById(id2).style.display="block";
    },
    artificial(){
      this.$axios.get("/user/getInfo").then(res=>{
        var phone=res.data.phone
        if(phone===''||phone===null||phone.match(/^\s+$/))
        {
          this.addChat({id:nanoid(),isUser:false,isList:false,contain:"管理员暂未预留联系方式，请留言咨询"})
          return
        }
        const tem="<strong class='token bold' style='color: rgb(89, 191, 192);'><span data-slate-string='true'>"+phone+"</span></strong>"
        this.addChat({id:nanoid(),isUser:false,isList:false,contain:"人工方式可以通过联系："+tem+" 进行咨询"})
      }).catch(err=>{
        this.$message.error("服务端出错")
      })

    },
    goToQuestion(val){
      if(val==="")
        return;
      this.addChat({id:nanoid(),isUser:true,isList:false,contain:val});
      this.$axios.get("/answer/"+val).then(res=>{
        const answer =res.data.answer;
        this.addChat({id:nanoid(),isUser:false,isList:false,contain:answer});
      }).catch(err=>{
        console.log("gDialog/oToQuestion出错")
      })
    },

  },

}
</script>

<style scoped>
.statement{
  padding: 10px;
  font-size: 13px;
  color: gray;
  text-indent: 1em;
}
.card{
  width: 100%;
  height: 95%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  /*border-radius: 10px;*/
  overflow: hidden;
  display:block
}
.header{
  background: linear-gradient(220.55deg, #7CF7FF 0%, #1161d5 100%);

  color: #000000FF;
  text-align:left;
  line-height: 60px;
  width: 100%;
  padding: 0;
  font-size: 20px;

}
.footer {
   padding: 0;
   height: 100px;
   color: #333;
   background: #ffffff;
 }
.main{
   height: 440px;
   background: #f3f3f3;
   overflow-x:hidden;
   top:4em;
   bottom:0;
   width:100%;

 }
.edit>>>.el-textarea__inner{
  border: 0;
  resize: none;
  font-size: 18px;
}
.mainChat{
  /*width: 70%;*/
  display: inline-block;
}
.side{
  /*width: 30%;*/
  display: inline-block;
  height:540px;
  width: 100%;
}
.pan1-refresh{
   text-align: center;
   font-size: 12px;
   color: darkgray;
}

.pane1-div{
  padding: 15px 20px;
  cursor: pointer;
  border-bottom: none;
}
.pane1-number{
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  margin-right: 10px;
  color: #008CEE;
}
.pane1-contain{
  padding: 0;
  color: #222;
  font-family: "Microsoft YaHei", Arial, sans-serif;
  font-size: 14px;
  margin: 0.5rem 0.5rem
}
.pane1-contain:hover,.pan1-refresh:hover{
  color: #409eff;
}
.pane1-contain:active,.pan1-refresh:active{
  color: rgb(240, 68, 60);
}
.chatPop1 :hover {
  background-color: #FAFAFA;
}
.chatPop1 span {
  background-color: #fff;
  padding: 5px 8px;
  display: inline-block;
  border-radius: 10px;
  margin: 0px 0 10px 10px;
  position: relative;
  border: 1px solid #E3E3E3;
  max-width: 290px;
}
.chatPop1 span:after{
  content: '';
  border: 8px solid #ffffff00;
  border-right: 8px solid #fff;
  position: absolute;
  top: 8px;
  left: -16px;
}
.chatPop2 :hover {
  background-color: #2683f5;
}
.chatPop2 span {
  background-color: #2683f5;
  padding: 5px 8px;
  display: inline-block;
  border-radius: 10px;
  margin: 0px 15px 0px 10px;
  position: relative;
  border: 1px solid #E3E3E3;
  max-width: 290px;
  float: right;
  color: #fff;
}
.chatPop2 span:after {
  content: '';
  border: 8px solid #ffffff00;
  border-right: 8px solid #2683f5;
  position: absolute;
  top: 8px;
  right: -16px;
  transform: rotateY(180deg)
}

#sendButton:hover{
  content:url(../assets/picture/sendHover.png);
}
#emoji:hover{
  content:url(../assets/picture/smailHover.png);
}
#message:hover{
  content:url(../assets/picture/messageHover.png);
}
#service:hover{
  content:url(../assets/picture/serviceHover.png);
}
#leaveMes/deep/.el-textarea__inner{
  height: 280px;
}
#result/deep/.el-result__subtitle p{
  font-size: 10px;

}
#icon-close:hover{
  color: #ff4c4c;
  font-size: 30px;
}

.oneEmoji{
  margin: 5px;
  width: 24px;
  height: 24px;
}
.oneEmoji:hover{
  margin: 5px;
  background: #409eff;
  width: 24px;
  height: 24px;

}

.side /deep/ .el-tabs--border-card {
  box-shadow: none;
}
.side /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  border-bottom-color: #409eff;
  border-bottom-width: 3px;
  box-shadow: 0 0 25px gray;
}
.side/deep/.el-tabs__content {
  height: 100%;
}
.side/deep/.el-tabs__nav{
  z-index: 0;
}


.card/deep/::-webkit-scrollbar {
  width: 5px;
  display: block;
}
.card/deep/::-webkit-scrollbar-thumb {
  background: linear-gradient(220.55deg, #7CF7FF 0%, #4B73FF 100%);
  border-radius: 8px;
}

</style>
