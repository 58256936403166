<template>
  <div style="width: 100%;margin-bottom: 20px">
    <el-row :gutter="1" style="border-width: 2px;border-bottom: 2px #dadde4 solid;">
      <el-col :span="24">
        <el-row style="text-align: left">
           <i class="el-icon-user-solid" style="color: dodgerblue"></i>
          <span class="email">邮箱：{{message.email}}</span>
          <span class="phone">电话：{{message.phone}}</span>
          <div v-if="message.messageStatus===0" class="new-div">
            <span class="time">{{message.messageTime}}</span>
            <span class="but-reply"><i class="el-icon-s-promotion icon" @click="tip">回复</i></span>
            <el-tooltip content="删除后可在历史记录中查询"  effect="light" placement="top">
              <span class="but-delete" ><i class="el-icon-delete-solid icon" @click="$emit('delete',message.messageId)">删除</i></span>
            </el-tooltip>
          </div>

        </el-row>
        <el-row class="contain">
             <span v-if="message.messageContent.length<maxLen">
                <span class="tj">{{message.messageContent}}</span>
            </span>
             <span v-else>
                <span class="tj">{{showBtn?sliceStr:message.messageContent}}
                    <span class="btnWord" @click="showBtn=!showBtn">{{showBtn?"展开":"收起"}}</span>
                </span>
             </span>
        </el-row>
        <el-row>
          <div>
          </div>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Message",
  data() {
    return {
      showBtn: true,
      maxLen:175,
    }
  },
  props: {
    message:{

    },
  },
  created() {
    if(this.message.messageStatus===0)
    this.message.messageTime=this.getDate( this.message.messageTime)
  },
  computed: {
    sliceStr() {
      if (this.message.messageContent != null) {
        return this.message.messageContent.substring(0, this.maxLen) + "...";
      }
      return '';
    }
  },

  methods:{
     tip(){
       this.$alert('请通过个人邮箱或者电话进行回复', '提示', {
         confirmButtonText: '确定',
       });
     }
  }
}
</script>

<style scoped>
.tj {
  text-align: justify;
  line-height: 1.8em;
  text-indent: 2em;
  display: inline-block;
}
.btnWord {
  color: cornflowerblue;
  cursor: pointer;
  word-break: keep-all;
}
.email{
  font-weight: bold;
  font-size: 15px;
  margin-left: 1%;
  color: #79b8ff;
  padding: 5px;
}
.phone{
  font-weight: bold;
  font-size: 15px;
  margin-left: 1%;
  color: #79b8ff;
  padding: 5px;
}
.new-div{
  display: inline-block;
}
.time{
  padding: 0 10px;
  font-size: 10px;
  color: #79b8ff;
}
.but-reply{
  padding: 0 10px;
}
.but-delete{
  padding: 0 10px;
}
.contain{
  font-size: 15px;
  text-align: left;
  color: #446087;
  margin: 10px 50px 10px 10px;
}
.icon{
  color: gray;
}
.icon:hover{
  font-weight: bold;
  color: dodgerblue;
}
</style>