
<template>
  <div>
    <div class="back" @click="()=>{this.$router.go(-1)}">
      <span class="back-span">
        <img src="../assets/picture/返回.png"  alt=""/>
      </span>
    </div>
    <div class="add-main">
      <div class="add-question" >
        <el-input type="textarea"  resize="none" placeholder="请输入问题..." v-model="question" />
      </div>
      <div class="add-answer">
        <MyEditor :html="answer" @editOk="upDateQuestion" :editText="'修改'"/>
      </div>
    </div>
  </div>
</template>

<script>
import MyEditor from "@/components/MyEditor";
export default {
  name: "EditPage",
  components:{MyEditor},
  data() {
    return {
      id:"",
      question:"",
      answer:"",
    }
  },
  created() {
    this.init()
  },
  methods:{
    upDateQuestion(html){
      if(this.question===""||this.question.match(/^\s+$/)) {
        this.$message({
          showClose: true,
          message: '问题不能为空！',
          type: 'warning'
        })
        return
      }
      else
        this.$axios.post("/question/updateQuestion", {id:this.id,question:this.question,answer:html} ).then(res=>{
          if(res.code==="200")
            this.$message({
              showClose: true,
              message: '更新成功！',
              type: 'success'
            });
          this.$router.go(-1);
        }).catch(err=>{
            this.$message.error("更新失败");
        })
    },
    init(){
      var tem=this.$route.params.contain
      this.id=tem.id
      this.question=tem.question
      this.answer=tem.answer
    },
  },
}
</script>

<style scoped>
.add-main{
  width: 80%;
  margin: auto;
  /*box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);*/
  border-radius: 5px;
}
.add-question{
  padding: 10px;
}
.add-question/deep/.el-textarea__inner{
  min-height: 50px;
  max-height: 200px;
  line-height: 25px;
  font-size: 18px;
  font-family: "微软雅黑 Light",serif;
  font-weight: bold;
}
.add-answer{
  padding: 10px;

}

.back{
  position: absolute;
  /*box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);*/
}
.back-span{
  position: relative;
  z-index: 9;
  left: 50px;
  top: 10px;

}

.back-span img{
  border-radius: 25%;
}
.back-span img:hover{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>