<template>
              <div style="overflow: auto;height: 100vh;text-align: center;padding: 50px">
                <el-empty description="暂无留言信息..." v-if="newMessage.length===0" :image="require('../../assets/picture/empty.png')"/>
                <div v-else>
                  <Message class="fadeInDown" :id="item.messageId" :key="item.messageId" :message="item"  @delete="deleteMes" @edit="edit" v-for="item in newMessage" />
                  <el-pagination
                      background
                      layout="prev, pager, next"
                      :current-page="currentPage"
                      :page-size="pageSize"
                      :total="total"
                      style="margin-bottom: 50px"
                      @current-change="getNewMessage"
                  >
                  </el-pagination>
                </div>

              </div>
</template>

<script>


import Message from "@/components/Message";

export default {
  name: "NewMessage",
  components:{Message},
  data() {
    return {
      currentPage:1,
      total:0,
      pageSize:1,
      newMessage:[],
    }
  },
  created() {
    this.getNewMessage(1)
  },
  methods:{
    getNewMessage(currentPage){
      this.$axios.get("/customer/getChatList/0/"+currentPage ).then(res=>{
        this.newMessage=res.data.list;
        this.currentPage = res.data.pageNum
        this.total = res.data.total
        this.pageSize = res.data.pageSize
      }).catch(err=>{
        console.log("Artificial/getNewMessage出错")
      })
    },
    deleteMes(messageId){
      console.log(messageId)
      this.$axios.post("/customer/updateMessage", {messageId} ).then(res=>{
        this.getNewMessage(1)
        this.$message({
          showClose: true,
          message: '处理成功！',
          type: 'success'
        }).catch(err=>{
          console.log("Artificial/deleteMes出错")
        })
        document.getElementById(messageId).style.display="none";
        //  this.getDealMessage()
      })
    },
    edit(email){
      console.log(email)
    },
  },
}
</script>

<style scoped>

</style>