import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store"
import Artificial from "@/views/Artificial";

import Login from "@/views/Login";

import Home from "@/views/Home";
import NewMessage from "@/views/childView/NewMessage";
import OldMessage from "@/views/childView/OldMessage";
import QuestionAdd from "@/views/childView/QuestionAdd";
import QuestionTable from "@/views/childView/QuestionTable";
import EditPage from "@/components/EditPage";
import EditPhone from "@/views/childView/EditPhone";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component:Home
  },
  {
    path: '/Artificial',
    name: 'Artificial',
    component: Artificial,
    redirect: '/newMessage',
    children:[
      {
        path:'/newMessage',
        name:"NewMessage",
        component:NewMessage,
      },
      {
        path:'/oldMessage',
        name:"OldMessage",
        component:OldMessage,
      },
      {
        path:'/questionAdd',
        name:"QuestionAdd",
        component:QuestionAdd,
      },
      {
        path:'/editPage',
        name:"EditPage",
        component:EditPage,
      },
      {
        path:'/editPhone',
        name:"EditPhone",
        component:EditPhone,
      },
      {
        path:'/questionTable',
        name:"QuestionTable",
        component:QuestionTable,
      }]
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
const whiteList = [ '/','/home','/Login'] //白名单,不需要登录的路由
const WhiteListK = ['/questionTable','/editPhone','/editPage','/questionAdd','/oldMessage','/newMessage','/Artificial']
router.beforeEach((to, from, next) => {
 // console.log(to.path + "  from:" + from.fullPath)
  const token = localStorage.getItem("token")
  //console.log(token)
  if(token != null && token !== "")
  {
    next();
  }else if(whiteList.indexOf(to.path) !== -1)
  {
      next()
  } else {
    if(whiteList.indexOf(to.path) !== -1){
      next()
    }else {
      if(WhiteListK.indexOf(to.path) !== -1){
        next({
          path: '/Login'
        })
      }
      /*next({
        path: '/Login'
      })*/
    }
  }

});

export default router
