<template>
  <div>
   <div class="background">
      <img src='../assets/picture/背景.png' width="100%" height="100%" alt="" />
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
             label-width="80px" class="demo-ruleForm">
      <el-tag style="font-size: large;margin-bottom: 20px;margin-left: 75px">
        湖北工业大学智能问答后台</el-tag>
      <el-form-item label="用户名" prop="username">
        <el-input v-model="ruleForm.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Login.vue",
  data() {
    return {
      ruleForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 0, max: 10, message: '0-10位', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this//保存当前全局this
          this.$axios.post(
              '/user/login',
              this.ruleForm
          ).then(result =>{
            const user = result.data.user
            _this.$store.commit("SET_USER",user)
            _this.$store.commit("SET_USERINFO",result.data.userInfo)
            _this.$router.push("/Artificial")
          }).catch(err=>{
            console.log(err)
          })
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style scoped>
.demo-ruleForm{
  /*width: 30%;
  margin: 0 auto;*/
  position: absolute;
  /*left: 0;*/
  right: 8%;
  top:34%;
  /* width: 400px;*/
  width:28%;
  height: 40%;
  /*width:50%;*/
  border-radius: 6px;
  padding: 35px 35px 15px 35px;
  margin: 0px auto;
  /*background-color:rgba(27, 28, 51,0.3);*/
  background-color: rgba(250,250,250,0.6);

}
.background {
  width:100%;
  height:100%;  /**宽高100%是为了图片铺满屏幕 **/
  z-index:-1;
  position: absolute;
}
</style>
