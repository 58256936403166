<template>
  <div class="main">
    <el-table :data="tableData" style="width: 100%" stripe>
      <el-table-column
          type="index"
          :index="indexMethod"
          min-width="4%">
      </el-table-column>
      <el-table-column min-width="20%"
          label="更新时间"
          prop="create_time">
      </el-table-column>
      <el-table-column min-width="40%"
          label="问题名称"
          prop="question">
      </el-table-column>
      <el-table-column min-width="16%"  label="问题答案">
        <template slot-scope="scope">
          <el-button type="text" @click="open(scope.row.answer)">点击查看</el-button>
        </template>
      </el-table-column>
      <el-table-column align="right" min-width="20%">
        <template slot="header" slot-scope="scope">
          <el-input
              v-model="search"
              size="mini"
              placeholder="输入关键字搜索" @keyup.enter.native="getQuestion(1)">
            <el-button slot="append" @click.native="getQuestion(1)" icon="el-icon-search"/>
          </el-input>

        </template>
        <template slot-scope="scope">
          <el-button style="margin:10px" size="mini" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
          <el-popconfirm title="确定删除这个问题吗？" @confirm="handleDelete(scope.$index, scope.row)">
            <el-button    slot="reference" size="mini" type="danger">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center;padding: 20px">
      <el-pagination
          background
          layout="prev, pager, next"
          :page-size="pageSize"
          :total="total"
          style="margin-bottom: 50px"
          @current-change="getQuestion"
      />
    </div>

  </div>
</template>

<script>
import EditPage from "@/components/EditPage";
export default {
  name: "QuestionTable",
  components:{EditPage},
  data() {
    return {
      currentPage:1,
      pageSize:10,
      total:0,
      tableData: [],
      tableCount:0,
      search: '',
    }
  },
  created() {
    this.getQuestion(1)
  },
  methods: {

    getQuestion(currentPage){
      console.log(currentPage)
      if(this.search===''||this.search.match(/^\s+$/))
        this.$axios.get("/question/getQuestions/"+currentPage ).then(res=>{
          this.total=res.data.total
          this.tableData=res.data.list
          this.currentPage=res.data.pageNum
          this.tableCount=this.tableData.length
        }).catch(err=>{
          this.$message.error("获取问题列表失败")
        })
      else
        this.$axios.get("/question/getQuestions/"+this.search+"/"+currentPage ).then(res=>{
        this.total=res.data.total
        this.tableData=res.data.list
        this.currentPage=res.data.pageNum
        this.tableCount=this.tableData.length
      }).catch(err=>{
        this.$message.error("获取问题列表失败")
      })
    },
    handleEdit(index, row) {
      this.$router.push({name: 'EditPage',params:{ contain:row}});
    },
    handleDelete(index, row) {
     // console.log(index, row);
      this.tableCount--;
      this.$axios.post("/question/deleteQuestion", {id:row.id} ).then(res=>{
        if(res.code==="200")
          this.$message.success('删除成功！');
          const tem=this.tableCount===0?this.currentPage-1:this.currentPage
          this.getQuestion(tem)
      }).catch(err=>{
          this.$message.error("更新失败");
      })
    },
    open(val) {
      let tem=this.imgTagAddStyle(val)
      this.$alert(tem, '问题答案：', {
        dangerouslyUseHTMLString: true,
        customClass:"alert",

      });
    },
    //限制富文本中img大小
    imgTagAddStyle(htmlstr){
      //正则匹配所有img标签
      //var regex0 = new RegExp("(i?)(\<img)([^\>]+\>)","gmi");
      //正则匹配不含style="" 或 style='' 的img标签
      var regex1 = new RegExp("(i?)(\<img)(?!(.*?style=['\"](.*)['\"])[^\>]+\>)","gmi");
      //给不含style="" 或 style='' 的img标签加上style=""
      htmlstr = htmlstr.replace(regex1, "$2 style=\"\"$3");
      //正则匹配含有style的img标签
      var regex2 = new RegExp("(i?)(\<img.*?style=['\"])([^\>]+\>)","gmi");
      //在img标签的style里面增加css样式(这里增加的样式：display:block;max-width:100%;height:auto;border:5px solid red;)
      htmlstr = htmlstr.replace(regex2, "$2max-width:300px;$3");
      return htmlstr;
    },
    searchQuestion(value,index){
      this.$axios.get("/question/getQuestions/"+value+"/"+index).then(res=>{
        this.total=res.data.total
        this.tableData=res.data.list
        this.currentPage=res.data.pageNum
        this.tableCount=this.tableData.length
      }).catch(err=>{
        this.$message.error("查询问题列表失败")
      })
    },
    indexMethod(index) {
      return (index +1)+10*(this.currentPage-1);
    }
  },
}
</script>

<style scoped>
.main{
  padding: 10px;
}
.main/deep/.el-input-group__append{
  padding: 0 10px;
}
.main/deep/.el-table td.el-table__cell div {
  text-align: center;
}
.main/deep/.el-table th.el-table__cell>.cell{
  text-align: center;
}
</style>