import axios from "axios";
import router from "@/router";
import store from "@/store"
import Element from "element-ui";
//axios.defaults.baseURL="/api"

//前置拦截,在发ajax请求后执行，发到后端前
axios.interceptors.request.use(config => {
    config.headers = {
        "user": localStorage.getItem("token")
    }
    return config
})

//后置拦截，在得到后端数据后，返回前端前
axios.interceptors.response.use(
    response => {
        let res = response.data;
        if(res.code === "200"){
            return res
        }else if(res.code === 401) {
                 //设置错误弹窗信息以及弹出时间
                //使用element-ui中的错误信息弹窗
                // 验证token
                Element.Message.error(res.msg,{duration : 2*1000})
                //console.error("token过期，重新登录")
                router.push("/Login")
                return res;
                  //如果请求出现错误进行拦截不放回请求

        }else {
            Element.Message.error(res.msg,{duration : 2*1000})
            return Promise.reject(res.data.msg)
        }

    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)
export default axios
