<template>
  <div style="border: 1px solid #ccc;">
    <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        style="height: 500px; overflow-y: hidden;"
        v-model="answer"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
    />
    <div >
      <el-button class="but" type="primary" @click="butClick(answer)">{{editText}}</el-button>
    </div>
  </div>
</template>

<script>
import "@wangeditor/editor/dist/css/style.css"
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import store from "@/store";

export default {
  name: "MyEditor",
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      toolbarConfig: { },
      editorConfig: { placeholder: '请输入问题答案...'},
      mode: 'default', // or 'simple'
      answer:''
    }
  },
  created() {
    this.editorConfig={
      placeholder: '请输入问题答案...',
      MENU_CONF: {
        // 配置上传图片
        uploadImage: {
          //server: "/api/question/addImage",
          server: "/question/addImage",
          fieldName: 'file',
          // 自定义增加 http  header
          headers: {
            "user":store.state.user
          },
          // 单个文件上传成功之后
          onSuccess(file, res) {  // TS 语法
            // onSuccess(file, res) {          // JS 语法
            console.log(`${file.name} 上传成功`, res)
          },

          // 单个文件上传失败
          onFailed(file, res) {   // TS 语法
            console.log(`${file.name} 上传失败`, res)
          },

          // 上传错误，或者触发 timeout 超时
          onError(file, err, res) {  // TS 语法
            // onError(file, err, res) {               // JS 语法
            console.log(`${file.name} 上传出错`, err, res)
          },
        },
      },
    }
    this.answer=this.html
  },
  props:{
    html:{
      type:String,
      default:''
    },
    editText:{
      type:String,
      default:'提交'
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      this.toolbarConfig.excludeKeys=['insertVideo','uploadVideo']
    },
    butClick(value){
      if(value===""||value==='<p><br></p>')
      {
        this.$message({
          showClose: true,
          message: '答案不能为空！',
          type: 'warning'
        });
        return
      }
      this.$emit('editOk',value)
    }
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
    // }, 1500)
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>

<style scoped>
.but{
  float: right;
  position: relative;
  padding: 15px;
  margin: 10px;
  top:-70px;
  z-index: 9;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}
</style>

